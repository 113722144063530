import styled from "styled-components";
import ButtonLink from "./buttonInformation";

export const StadisticsContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 10%;
    padding-bottom: 10%;

    .title-container {
        text-align: center;
        line-height: 38px;

        @media(min-width: 1024px){
            margin: 3rem 0;
        }
    }

    .wrapper {
        
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        @media (max-width: 1024px){

            margin-top: ${props => props.portrait ? `10% !important` : `5 !important`};
            margin-bottom: 2rem;
            flex-direction: column !important;
            align-items: center;
            justify-content: center;
        }
        
    }

`;

export const DivCounter = styled.div`

    width: 300px;
    background-color: #2222229e;
    color: #fff;
    text-align: center;
    border-radius: 100px;
    font-family: 'Bebas Neue Bold';
    margin: 0 1rem;


    @media(max-width: 1024px){
        margin-bottom: 20px;
    }

    .container {
        margin-top: 5%;
    }

    .info-row{

        display: flex;
        display-direction: row;
        justify-content: center;

        .icon {
            font-size: 50px;
            margin-bottom: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .title-logo {
            margin-left: 0.5rem;
        }
        h1{
            
            font-family: 'Bebas Neue Bold';
            font-size: 2.25rem;
            
            @media (max-width: 1024px){
                margin-top: 8px;
            }
        }

        h4 {
            width: 100%;
            font-family: 'Bebas Neue Bold';
            font-size: 20px;
            margin-top: -20px;
        }
       
    }
`;



export const Title = styled.h1`
  font-size: 38px !important;
  line-height: 38px !important;
  font-weight: 700;
  color: #fff;
  padding: 0 5vw;
  text-shadow: rgb(10 10 10) 1px 1px 7px;
  margin-top: 2rem;

  @media(min-width: 1024px){
      margin-top: 3rem;
  }
`;
