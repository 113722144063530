import  React, {useLayoutEffect, useState} from "react";
import { DivCounter, StadisticsContainer, Title  } from "./counterComponents";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import HtmlParser from "react-html-parser";

const Counter = (props) => {

    const [activeCount, setActiveCount] = useState(false);
    const [isMobile, setIsMobile] = useState(false);


    useLayoutEffect(() => {

        if(typeof window !== "undefined"){

                        
            const activateSensor = () => {
  
                if(window.scrollY > 2600 && !activeCount){
                    setActiveCount(true);
                    window.removeEventListener("scroll",activateSensor);
                }
            }

            const consultWidth = () => {
                
                if(window.screen.width > 768){
                    setIsMobile(false);
                }else{
                    setIsMobile(true);
                }
            };

            window.addEventListener("scroll",activateSensor);
            window.addEventListener("resize",consultWidth);
        }
    },[]);
    
    const onChange = (start) => {

        if(typeof start === "function"){
            
            if(activeCount){
                setActiveCount(false);
            }
        }
    }
    return(
        <>
            <StadisticsContainer portrait={props.portrait}>

            <div className="title-container">
                        <Title>{props.title}</Title>
            </div>


                <div class="wrapper">
                    <DivCounter>
                           <div class="container">
                                <div class="info-row">
                                    <div class="icon">
                                        <span class="icon-iconos_Mesa-de-trabajo-1"></span>
                                    </div>

                                    <div class="title-logo"
                                                        >
                                        <h1>+ <CountUp start={0} end={20} redraw={true} duration={3}>
                                                {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={onChange(start)} delayedCall
                                                active={activeCount}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                                )}
                                            </CountUp></h1>
                                    </div>
                                </div>

                                <div class="info-row">
                                    {HtmlParser(props.items[0].title)}
                                </div>
                            </div>
                    </DivCounter>

                    <DivCounter>
                        <div class="container">
                            <div class="info-row">
                                <div class="icon">
                                    <span class="icon-intervenciones-electivas"></span>
                                </div>
                                <div class="title-logo">
                                    <h1>+ <CountUp 
                                                start={100} 
                                                end={580000} 
                                                redraw={true}
                                                formattingFn={(value) => value.toLocaleString('en-US', { useGrouping: false })}>
                                                {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={onChange(start)} delayedCall active={activeCount}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                                )}
                                            </CountUp></h1>
                                </div>
                            </div>

                            <div class="info-row">
                            {HtmlParser(props.items[1].title)}
                            </div>
                        </div>
                    </DivCounter>

                    <DivCounter>
                        <div class="container">
                            <div class="info-row">
                                <div class="icon">
                                    <span class="icon-casos-resueltos-"></span>
                                </div>
                                <div class="title-logo">
                                    <h1>+ <CountUp 
                                                start={0} 
                                                end={9500} 
                                                redraw={true}
                                                formattingFn={(value) => value.toLocaleString('en-US', { useGrouping: false })}>
                                                {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={onChange(start)} delayedCall active={activeCount}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                                )}
                                            </CountUp></h1>                            
                                </div>
                            </div>

                            <div class="info-row">
                            {HtmlParser(props.items[2].title)}
                            </div>
                        </div>
                    </DivCounter>
                </div>

            </StadisticsContainer>
        </>
    );
};

export default Counter;
