import React, { useState, useLayoutEffect, useEffect } from "react";
import { BackgroundContainer, Title } from "./parallaxComponents"
import Counter from "./counter";
import { useWindowSize } from "../utils/hooks";


const Statistics = (props) => {

  const [progress, setProgress] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const size = useWindowSize();

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  useLayoutEffect(() => {

    if(typeof window !== "undefined"){

      const updateSize = () => {
      
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      };

      window.addEventListener("resize",updateSize);
    }
  }, []);


  return (
    <BackgroundContainer portrait={height > width ? true : false} height={height}> 
      <Counter portrait={height > width ? true : false} height={height} items={props.items} title={props.title}></Counter>
    </BackgroundContainer>
  );
};

export default Statistics;
