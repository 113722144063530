import styled from "styled-components";
import { rhythm, scale } from "../utils/typography";
import { Container } from "../Elements/Container";
import img from "../img/parallax-profesional-trabajando.jpg";

export const BackgroundContainer = styled.div`

background-image: url(${img});
background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
/*height: ${props => props.portrait === true ? '100vh' : `none`}} */

@media (min-width: 1026px){
  height: 100vh !important;
}

.count-indicator {
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  font-size: 2.2em;
  font-weight: 100;

  user-select: none;
  color: white;
  text-shadow: 2px 4px 9px black;
}
.RCP {
  width: 100% !important;
  height: 100% !important;
}
.RCP svg {
  width: 100% !important;
  height: 100% !important;
}
`;

export const StyledContent = styled(Container)`
  justify-content: space-around;
  color: white;
  padding-top: ${rhythm(4)};
  padding-bottom: ${rhythm(4)};

  

  @media screen and (max-width: 1023px) {
    flex-direction: column !important;
  }
  h1 {
    margin-bottom: ${rhythm(2)};
  }
  h1,
  h2,
  h3 {
    text-align: center;
    font-weight: 300;
  }
  a {
    ${scale(-0.2)};
    text-transform: uppercase;
    font-weight: 700;
    background: #222;
    min-width: 170px;
    color: #91c508;
    padding: 10px 20px;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    text-decoration: none;
    &:hover {
      background: #91c508;
      color: #222;
    }
  }

  .statistics {

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    span {
      font-family: "Bebas Neue Bold";
      font-size: 58px;
    }
    h1 {
      font-family: 'Roboto' !important;
      text-shadow: black 2px 4px 9px;
      margin: 0px;
      line-height: 1 !important;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: ${rhythm(1)};
      font-size: 2rem !important;

      @media (max-width: 1023px){
        font-size: 17px !important;
      }
    }
    @media screen and (max-width: 1023px) {
      max-width: 200px !important;
      span {
        font-size: 42px !important;
      }
      h1 {
        ${scale(0.5)};
      }
    }
    @media screen and (max-width: 1023px) {
      max-width: 100% !important;
      flex-direction: row !important;
      &:nth-of-type(odd) {
        justify-content: flex-end;
      }
      .RCP {
        max-width: 180px;
      }
      span {
        font-size: 32px !important;
      }
      h1 {
        font-family: 'Roboto' !important;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        ${scale(0.5)};
      }
      @media screen and (max-width: 450px) {
        .RCP {
          max-width: 135px;
        }
      }
      @media screen and (max-width: 355px) {
        h1 {
          ${scale(-0.125)};
        }
      }
    }
  }
`;
