import React from "react";
import styled from "styled-components"
import { rhythm, scale } from "../utils/typography";
import { Container } from "../Elements/Container";
import { Link } from "gatsby";

/*const ButtonLink = styled.a`

    ${scale(-0.2)};
    text-transform: uppercase;
    font-weight: 700;
    background: #fff;
    min-width: 170px;
    color: #222;
    padding: 10px 20px;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    text-decoration: none;
    width: 177px;
    height: 47px;
    border: 1px solid #222;
     white-space: nowrap;
`; */

const StyledButtonLink = styled.div`

    width: 177px;
    height: 40px !important;
    display: flex;
    text-align: center;
    text-decoration: none;

    .link{
        width: 177px;
        text-decoration-line: none;
        text-transform: uppercase;
        height: 40px;
        border: 1px solid rgb(34, 34, 34);
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-weight: 700;
        color: #222;
        font-size: 15px;

        &:hover {
            background-color: #ededed;
            border: none;
        }
    
    }
    }
`;

const ButtonLink = (props) => {


    return(
        <>
            <StyledButtonLink>
                <Link to={props.href} className="link">
                    {props.title}
                </Link>
            </StyledButtonLink>
        </>
    );
};

export default ButtonLink;
