import React, { useState, useLayoutEffect} from "react";
import Layout from "../layout";
import SetLang from "../components/setLang";
import Quote from "../components/quote";
import Features from "../components/features";
import Brand from "../components/brand";
import Boxes from "../components/boxes";
import SEO from "../components/seo";
import Statistics from "../components/statistics";
import Carousel from "../components/carousel";
import Gallery from "../components/gallery";
import Hero from "../components/hero";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { ContactWidget } from "../components/ContactWidget";
import { ContactPopUp } from "../components/contactPopUp";



export const HomePageTemplate = ({
  hero,
  brand,
  gallery,
  features,
  quote,
  statistics,
  testimonials,
  procedures
}) => {
  const lazyLightBox = {
    type: gallery.type,
    carousel: {
      display: true
    },
    placeholder: gallery.carousel.placeholder,
    images: gallery.carousel.items.map((i, k) => {
      return {
        renderItem: () => {
          return (
            <Img critical={true}
              alt={`gallery-${k}`}
              className="lightbox-lazy"
              fluid={i.childImageSharp.fluid}
            />
          );
        }
      };
    })
  };
  return (
    <div>
      <Hero  className="home-parallax" {...hero}></Hero>
      <Brand {...brand}></Brand>
      <Gallery isMasory={true} {...lazyLightBox} items={gallery.items} />
      <Features {...features}></Features>
      <Quote {...quote} />
      <Statistics {...statistics}></Statistics>
      <Carousel {...testimonials}></Carousel>
      <Boxes {...procedures}></Boxes>
    </div>
  );
};

const HomePage = ({ data }) => {

  const {
    templateKey,
    language,
    title,
    description,
    keywords,
    ogImage,
    siteName,
    redirects,
    hero,
    brand,
    gallery,
    quote,
    statistics,
    testimonials,
    features,
    procedures
  } = data.markdownRemark.frontmatter;

  const [isContactWidgetOpen, setIsContactWidgetOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait,setIsPortrait] = useState(false);

  const changeOpenWidget = () => {
    /*if(isMobile){
      if(isPortrait){
        setIsContactWidgetOpen(!isContactWidgetOpen);
      }
    }else{
      setIsContactWidgetOpen(!isContactWidgetOpen);
    } */
    setIsContactWidgetOpen(!isContactWidgetOpen);
  };

  useLayoutEffect(() => {

    if(typeof window !== "undefined"){
      
      const updateSize = () => {

          let width = window.screen.width;
          let height = window.screen.height;

          if(width < 1026){
      
            setIsMobile(true);
          }else{
          
            setIsMobile(false);
          }

          if(height > width){
        
            setIsPortrait(true);
          }else{
         
            setIsPortrait(false);
          }
      };

      window.addEventListener("resize",updateSize);
    }
  });

  return (
    <Layout>
      <SetLang language={language} link={redirects} />
      <SEO
        title={title}
        lang={language}
        description={description}
        keywords={keywords}
        ogImage={ogImage}
        siteName={siteName}
      />
      <HomePageTemplate
        {...{
          templateKey,
          language,
          title,
          redirects,
          hero,
          brand,
          gallery,
          quote,
          statistics,
          testimonials,
          features,
          procedures
        }}
      />
      
      <div>
        <ContactWidget changeOpenWidget={changeOpenWidget}/>

        {isContactWidgetOpen == true ? (
          <>
           <ContactPopUp changeOpenWidget={changeOpenWidget} />
          </>
        ) : <></>}

      </div>
      
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "home-page" } }
    ) {
      frontmatter {
        language
        title
        description
        ogImage
        siteName
        keywords
        redirects
        hero {
          isHome
          background {
            scaleOnReveal
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [ 1600 ], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            isParallax
          }
          anim {
            display
            type
          }
          height
          indicator
          portraitPosition
          content {
            position
            body
          }
        }
        brand {
          logo {
            publicURL
          }
          title
          main
          partners {
            image {
              childImageSharp {
                fluid(srcSetBreakpoints: [ 160 ], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
          footer
        }
        gallery {
          type
          carousel {
            display
            placeholder
            items {
              childImageSharp {
                fluid(srcSetBreakpoints: [ 1200 ], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          items {
            link {
              display
              to
            }
            image {
              childImageSharp {
                fluid(srcSetBreakpoints: [ 450 ], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            action
            placeholder
            body
          }
        }
        quote {
          body
          footer {
            author
            details
          }
        }
        statistics {
          title
          image {
            childImageSharp {
              fluid(srcSetBreakpoints: [ 1500 ], quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          items {
            number
            title
          }
        }
        testimonials {
          title
          items {
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [ 250 ], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            name
            testimonial
            position
          }
        }
        features {
          title
          description
          features {
            to
            icon
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [ 225 ], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            description
          }
        }

        procedures {
          title
          procedures {
            title
            to
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [ 550 ], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
